.login-admin-1 {
  min-height: 100vh;
  position: relative;
  padding-bottom: 20px;
  border-top: 15px solid #8C0000;
  border-bottom: 15px solid #8C0000;
  display: flex;
  align-items: center;
  font-family: "Sarabun", sans-serif;
  background-color: white;
}

form.adminForm {
  background: #181818;
  padding: 3em;
  height: 410px;
  border-radius: 20px;
  border-left: 1px solid #181818;
  border-top: 1px solid #181818;
  backdrop-filter: blur(10px);
  box-shadow: rgba(0, 0, 0, 1.35) 0px 5px 15px;
  text-align: center;
  position: relative;
  transition: all 0.2s ease-in-out;
  border-top: 5px solid #8C0000;
}

.input-container {
  padding-bottom: 1em;
}

p.prf-hed {
  font-weight: 500;
  color: #fff;
  opacity: 0.7;
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 45px;
  /* margin-bottom: 60px; */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

i.bi.bi-person-fill {
  position: absolute;
  /* padding: 12px 12px; */
  /* pointer-events: none; */
  color: #8c8e90;
  margin-top: 13px;
  margin-left: 13px;
}

i.bi.bi-lock-fill {
  position: absolute;
  /* padding: 12px 12px; */
  /* pointer-events: none; */
  color: #8c8e90;
  margin-top: 13px;
  margin-left: 13px;
}

i.bi.bi-envelope {
  position: absolute;
  color: #8c8e90;
  margin-top: 13px;
  margin-left: 13px;
}

input.userField,
input.passField {
  background: rgba(255, 255, 255, 0.1);
  width: 300px;
  padding: 1em;
  margin-bottom: 10px;
  border: none;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  border-radius: 5000px !important;
  /* backdrop-filter: blur(5px); */
  box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  padding-left: 35px !important;
}

@media (max-width: 320px) {
  input.userField, input.passField {
    max-width: 235px !important;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  form.adminForm {
    margin-bottom: 30px;
  }
}


  input.adminBtn {
    background: transparent;
    padding: 1em;
    margin-bottom: 1em !important;
    margin-top: -1em !important;
    border: none;
    border-left: 1px solid #fff !important;
    border-top: 1px solid #fff !important;
    background-color: #640505 !important;
    border-radius: 5000px !important;
    backdrop-filter: blur(5px);
    color: #fff !important;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2) !important;
    width: 150px !important;
    height: auto !important;
    font-size: 1rem !important;
  }

  input.adminBtn:hover {
    background: rgb(40 40 40 / 50%) !important;
    box-shadow: 0px 0px 26px 8px rgb(111 111 111 / 40%) !important;
  }

  input[type="text"],
  input[type="password"]:focus {
    background: rgba(255, 255, 255, 0.1);
    /* box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2); */
  }


  ::placeholder {
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    color: #fff;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); */
  }

  a.forgot {
    text-decoration: none;
    color: #ddd;
    font-size: 12px;
  }

  a.forgot:hover {
    text-shadow: 2px 2px 6px #fefefe70;
  }

  a.forgot:active {
    text-shadow: none;
  }

  a.backBtn {
    text-decoration: none;
    color: #ddd;
    font-size: 12px;
  }

  a.backBtn:hover {
    text-shadow: 2px 2px 6px #fefefe70;
  }

  a.backBtn:active {
    text-shadow: none;
  }


  .drop {
    background: rgba(140, 0, 0, 1);
    backdrop-filter: blur(10px);
    border-radius: 60px;
    border-left: 1px solid rgba(140, 0, 0, 1);
    border-top: 1px solid rgba(140, 0, 0, 1);
    box-shadow: 10px 10px 60px -8px rgba(0, 0, 0, 0.2);
    position: absolute;
    opacity: 0.6;
    transition: all 0.2s ease;
  }

  .drop.drop-1 {
    height: 80px;
    width: 80px;
    top: -20px;
    left: -40px;
    z-index: -1;
  }

  .drop.drop-2 {
    height: 80px;
    width: 80px;
    bottom: -30px;
    right: -10px;
  }

  .drop.drop-3 {
    height: 100px;
    width: 100px;
    bottom: 120px;
    right: -50px;
    z-index: -1;
  }

  .drop.drop-4 {
    height: 120px;
    width: 120px;
    top: -60px;
    right: -60px;
  }

  .drop.drop-5 {
    height: 100px;
    width: 100px;
    bottom: 120px;
    left: -40px;
    top: 10rem;
    z-index: -1;
  }

  .login-admin-1 .container {
    max-width: 1593px;
  }

  .login-admin-1 .logo {
    margin-bottom: 30px;
    margin-top: 15px;
    max-width: 370px;
  }

  .login-admin-1 .app-link a {
    display: block;
    width: 236px;
    float: left;
    width: 46.5%;
  }

  .login-admin-1 .app-link a:last-child {
    float: right;
  }

  .login-admin-1 .black-box {
    background-color: #181818;
    padding: 60px;
    box-shadow: rgba(0, 0, 0, 1.35) 0px 5px 15px;
  }

  .login-admin-1 .black-box .input-group {
    margin-bottom: 18px;
  }

  .login-admin-1 .black-box .input-group img {
    width: 18px;
  }

  .login-admin-1 .black-box .input-group .input-group-text {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #ffffff;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .login-admin-1 .black-box .form-control {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #ffffff;
    border-radius: 0;
    font-size: 14px;
    color: #ffffff;
    font-weight: 400;
  }

  .login-admin-1 .black-box .form-control::-webkit-input-placeholder {
    /* Edge */
    color: #ffffff;
    font-weight: 100;
  }

  .login-admin-1 .black-box .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ffffff;
    font-weight: 100;
  }

  .login-admin-1 .black-box .form-control::placeholder {
    color: #ffffff;
    font-weight: 100;
  }

  .login-admin-1 .black-box .form-control:focus {
    box-shadow: none;
    outline: none;
  }

  .login-admin-1 .black-box input[type="submit"] {
    width: 100%;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    height: 45px;
    background: #8C0000;
    border: 0;
    outline: none;
    box-shadow: none;
    margin-top: 17px;
    margin-bottom: 20px;
  }

  .login-admin-1 .black-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .login-admin-1 .black-box ul li {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 15px;
    min-height: 45px;
    position: relative;
    padding-right: 115px;
  }

  .login-admin-1 .black-box ul li:last-child {
    margin-bottom: 0;
  }

  .login-admin-1 .black-box ul li a {
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #8C0000;
    color: #ffffff;
    font-size: 16px;
    border: 0;
    outline: 0;
    text-decoration: none;
  }

  .login-admin-1 .black-box input[type="submit"],
  a {
    transition: all 0.4s;
    text-decoration: none;
  }

  .login-admin-1 .black-box input[type="submit"]:hover,
  .login-admin-1 .black-box ul li a:hover {
    background: #630006;
    transition: all 0.4s;
  }

  .login-admin-1 .black-box .input-group.is-danger .input-group-text {
    border-bottom-color: red;
  }

  .login-admin-1 .black-box .input-group.is-danger .input-group-text i {
    filter: invert(48%) sepia(86%) saturate(7443%) hue-rotate(350deg) brightness(90%) contrast(134%);
  }

  .login-admin-1 .black-box .input-group.is-danger .form-control {
    color: red;
    border-bottom-color: red;
  }

  .login-admin-1 .black-box .input-group.is-danger p.help.is-danger {
    display: block;
    width: 100%;
    margin: 0;
    color: red;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
  }

  .login-admin-1 .black-box .input-group.is-danger .form-control::-webkit-input-placeholder {
    /* Edge */
    color: red;
  }

  .login-admin-1 .black-box .input-group.is-danger .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
  }

  .login-admin-1 .black-box .input-group.is-danger .form-control::placeholder {
    color: red;
  }

  /* responsiveness  */

  @media (min-width: 992px) and (max-width: 1199px) {
    .login-admin-1 .logo {
      max-width: 315px;
    }

    .login-admin-1 .black-box {
      padding: 30px 20px;
    }

    .login-admin-1 .black-box input[type="submit"],
    .login-admin-1 .black-box ul li a {
      font-size: 14px;
      height: 35px;
    }

    .login-admin-1 .black-box ul li {
      padding-right: 105px;
      min-height: 35px;
      font-size: 12px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .login-admin-1 .logo {
      margin-top: 18px;
      margin-bottom: 24px;
      max-width: 90%;
    }

    .login-admin-1 .app-link a {
      width: 40%;
    }

    .login-admin-1 .black-box {
      padding: 30px 20px;
    }

    .login-admin-1 .black-box input[type="submit"],
    .login-admin-1 .black-box ul li a {
      font-size: 14px;
      height: 35px;
    }

    .login-admin-1 .black-box ul li {
      padding-right: 105px;
      min-height: 35px;
      font-size: 12px;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .login-admin-1 .logo {
      margin-top: 18px;
      margin-bottom: 24px;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .login-admin-1 .app-link a {
      width: 40%;
    }

    .login-admin-1 .black-box {
      padding: 30px 20px;
    }

    .login-admin-1 .black-box input[type="submit"],
    .login-admin-1 .black-box ul li a {
      font-size: 14px;
      height: 35px;
    }

    .login-admin-1 .black-box ul li {
      padding-right: 105px;
      min-height: 35px;
      font-size: 12px;
    }
  }

  p.copyright {
    bottom: 2px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    margin: 0 auto;
    max-width: 1593px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    right: 0;
    text-shadow: 0px 0px 1px #18181885, 0 0 1em #18181885, 0 0 0.2em #6c757d;
    color: #000;
  }

  i {
    color: #fff;
  }

  /* @media (min-width: 320px) and (max-width: 767px) {
p.copyright{
  margin-top: 5px;
  margin-bottom: 5px;
}
} */