.alred-memb {
    align-items: center;
    display: flex;
    font-family: Sarabun,sans-serif;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    margin-left: AUTO;
    margin-top: -65px;
    color: #fff;
}
@media (min-width: 320px) and (max-width: 768px){
    .alred-memb {
        font-size: 13px;
        margin-left: 48px;
        padding: 53px 20px;
}
}
/* @media (min-width: 320px) and (max-width: 767px){
    a.round-red-btn {
    font-size: 13px !important;
    margin-left: 23px !important;
    padding: 5px 20px !important;
}
} */
.alred-memb a.round-red-btn {
    font-size: 16px;
    margin-left: 15px;
    padding: 8px 35px;
}
.round-red-btn, .round-red-btn:hover {
    color: #fff;
    text-decoration: none;
    transition: all .4s;
}
.round-red-btn {
    align-items: center;
    background: #000;
    border: 1px solid #000;
    border: 0;
    border-radius: 30px;
    display: flex;
    font-family: Sarabun,sans-serif;
    font-size: 18px;
    font-weight: 400;
    justify-content: center;
    padding: 10px 28px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.round-red-btn:hover {
    background: #B51019;
    border: 1px solid #B51019;

}
@media (min-width: 320px) and (max-width: 767px){
.slide-heading1 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 22px !important;
    overflow: hidden;
    top: -1px !important;
    text-overflow: ellipsis;
}
}
@media (min-width: 320px) and (max-width: 767px){
    .slide-heading1 span {
    color: #fff;
    display: block !important;
    font-weight: 700 !important;
}
}