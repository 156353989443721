.adminForm.nointernet {
    background: #181818;
    padding: 3em;
    height: 410px;
    border-radius: 20px;
    border-left: 1px solid #181818;
    border-top: 1px solid #181818;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: rgba(0, 0, 0, 1.35) 0px 5px 15px;
    text-align: center;
    position: relative;
    transition: all 0.2s ease-in-out;
    border-top: 5px solid #8C0000;
  }
  /* ::marker {
    color: #B51019;
    } */
    li#des {
    color: #B51019;
    }
    li.mt-2.inner {
      list-style: none;
    }
    a.adminBtn {
      background: transparent;
      padding: 1em;
      margin-bottom: 1em !important;
      margin-top: -1em !important;
      border: none;
      border-left: 1px solid #fff !important;
      border-top: 1px solid #fff !important;
      background-color: #640505 !important;
      border-radius: 5000px !important;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      color: #fff !important;
      font-family: Montserrat, sans-serif;
      font-weight: 500;
      transition: all 0.2s ease-in-out;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2) !important;
      width: 150px !important;
      height: auto !important;
      font-size: 1rem !important;
  }
  a.adminBtn:hover {
    background: rgb(40 40 40 / 50%) !important;
    box-shadow: 0px 0px 26px 8px rgb(111 111 111 / 40%) !important;
  }
  span#siteName {
    font-weight: 600;
  }
  i.bi.bi-wifi-off.prf-hed {
    font-size: 40px;
    opacity: 0.7;
  }
  img.errorIcon {
    width: 100px;
}