div#RegisterBox {
  max-width: 960px !important;
  margin: 0 auto !important;
  /* border-radius: 8px; */
  /* margin-top: -240px !important; */
  margin-top: -52px !important;
  /* z-index: 1111 !important; */
  position: relative !important;
  /* padding: 1px 42px !important; */
  margin-top: 16px !important;
}

h2.slide-heading1 {
  color: #b51019;
  font-family: Oswald, sans-serif;
  font-size: 40px;
  font-weight: 300;
  left: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -61px;
  width: 100%;
  text-overflow: ellipsis;
  text-transform: uppercase;
  text-align: center;
}

h6.alertInfo {
  position: relative;
  /* margin-bottom: 2rem; */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #004085;
}

.slide-heading1 span {
  color: #fff;
  font-weight: 700;
}

.chek-term {
  margin-top: 15px;
}

.radio-custom,
.radio-custom-label {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

.radio-custom {
  opacity: 0;
  position: absolute;
}

.radio-custom {
  opacity: 0;
  position: absolute;
}

.radio-custom-label {
  display: block;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  position: relative;
}

.radio-custom,
.radio-custom-label {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

.radio-custom + .radio-custom-label:before {
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 23px;
  margin-right: 10px;
  margin-top: -2px;
  text-align: center;
  vertical-align: middle;
  width: 23px;
}

.chek-term a {
  color: #000;
  font-weight: 500;
  text-decoration: none;
}

.chek-term a[target="_blank"] {
  color: #b51019;
}

li.circle:before {
  border: 2px solid #b51019;
  /* width: 30px !important; */
  border-radius: 50%;
  content: "";
  height: 12px;
  position: absolute;
  /* top: 6px; */
  width: 12px;
  margin-left: -19px;
  margin-top: 6px;
}

.form-filds .form-floating > label > span {
  color: #b51019;
}

.select-leading {
  position: relative;
}

.select-leading span.spinner-border.spinner-border-sm {
  position: absolute;
  right: 35px;
  z-index: 1;
  top: 20px;
}

.prfil-set button:not(:disabled) .spinner-border-sm,
.prfil-set [type="button"]:not(:disabled) .spinner-border-sm,
.prfil-set [type="reset"]:not(:disabled) .spinner-border-sm,
.prfil-set [type="submit"]:not(:disabled) .spinner-border-sm {
  display: none;
}

.prfil-set button:disabled,
.prfil-set [type="button"]:disabled .spinner-border-sm,
.prfil-set [type="reset"]:disabled .spinner-border-sm,
.prfil-set [type="submit"]:disabled .spinner-border-sm {
  display: inline-block;
  margin-right: 5px;
}

.les-tp h6 {
  margin-top: 15px;
}

.store-details h6 {
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin: 30px 0 0;
}

.store-details h6 span {
  display: block;
  font-weight: 400;
}

/* span.spinner-border.spinner-border-sm.cityLoader {
    position: absolute;
    right: 42%;
    z-index: 1;
    top: 12.5%;
} */
/* span.spinner-border.spinner-border-sm.userCityLoader {
    position: absolute;
    right: 42%;
    z-index: 1;
    top: 65%;
} */
/* span.spinner-border.spinner-border-sm.storeLoader {
    position: absolute;
    right: 11%;
    z-index: 1;
    top: 12.5%;
} */

.radio-custom:checked + .radio-custom-label:before {
  background-color: #00a9e0;
  background-repeat: no-repeat;
  border: 2px solid #fff;
  box-shadow: 0 0 8px 1px #00a9e0;
  outline: 2px solid #00a9e0;
}

.form-floating.is-danger {
  border-color: 1px soild red;
  color: red;
}

.form-floating.is-danger input.form-control,
.form-floating.is-danger .form-select {
  border: 1px solid red;
  color: red;
}

.form-filds .form-floating.is-danger > label > span {
  color: red !important;
}

label.radio-custom-label.is-danger {
  color: red;
}

label.radio-custom-label.is-danger:before {
  border: 2px solid red;
  content: "";
}

label.radio-custom-label.is-danger a {
  color: red;
}

input#radio-3 {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  opacity: 0;
  position: absolute;
}

section.slide-up.registerSide {
  margin-top: -262px;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  margin-top: 4px;
  padding-bottom: 2px;
  padding-top: 18px;
}
.css-1dimb5e-singleValue {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.css-13cymwt-control,
.css-t3ipsp-control {
  margin-top: 4px;
  padding-bottom: 2px;
  padding-top: 18px;
}
.with-select {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.error-select-search .css-13cymwt-control,
.error-select-search .css-t3ipsp-control {
  border: 1px solid red !important;
  box-shadow: none !important;
}
.form-floating.is-danger .css-1jqq78o-placeholder {
  color: #f44336 !important;
}
.css-t3ipsp-control,
.form-floating .form-control:focus {
  border: 1px solid #b51019 !important;
  box-shadow: none !important;
}
@media (min-width: 768px) and (max-width: 1023px) {
  section.slide-up.registerSide {
    margin-top: -225px !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  section.slide-up.registerSide {
    margin-top: -316px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1800px) {
  .main-ban {
    background-position-x: center;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-ban {
    background-position-x: center;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sliding-bg {
    height: 460px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-ban .container {
    max-width: 1108px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .form-box {
    background-color: #fff;
    box-shadow: 0 0 14px 0 #92929254;
    margin-bottom: 20px;
    padding: 20px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  h4.prf-hed {
    color: #00a9e0;
    font-size: 22px;
  }
}

h4:first-child {
  border-top: 0 solid #ddd;
  padding-top: 0;
  font-family: Oswald, sans-serif;
  font-weight: 300;
}

@media (min-width: 320px) and (max-width: 767px) {
  ul.timeline:before {
    left: 3px;
    position: absolute;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  ul.timeline > li:before {
    left: -6px;
    position: absolute;
  }
}

.forgt-slide {
  background-image: url("/public/images/sleepelite-Forgotbg.jpg") !important;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 568px;
  position: relative;
  /* margin-top: 73px; */
}

.sliding-bg {
  background-image: url("/public/images/register-banner.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 437px;
  padding-top: 0px;
  position: relative;
  /* margin-top: -85px; */
}
@media (min-width: 768px) and (max-width: 992px) {
  .back-slid {
    height: auto;
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  form.forgot-frm {
    margin-bottom: 15px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .round-bor {
    height: 260px !important;
    width: 260px !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .round-with-bar {
    margin-top: 30px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .round-with-bar:after {
    background-color: #e38c67 !important;
    bottom: 0 !important;
    content: "" !important;
    height: 115px !important;
    left: 0 !important;
    margin: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    width: auto !important;
    z-index: -1 !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  form.forgot-frm .forgot-fild .form-control {
    height: 40px !important;
    padding-right: 150px !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  form.forgot-frm .forgot-fild input[type="submit"] {
    align-items: center !important;
    background-color: #fff !important;
    border: 0 !important;
    border-radius: 76px !important;
    bottom: 4px !important;
    color: #e38c67 !important;
    display: flex !important;
    font-family: Sarabun, sans-serif !important;
    font-size: 12px !important;
    font-weight: 400;
    justify-content: center !important;
    outline: none !important;
    padding: 0 15px !important;
    position: absolute !important;
    right: 4px !important;
    text-align: center !important;
    top: 4px !important;
    transition: all 0.4s !important;
    width: auto !important;
    height: auto;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  form.forgot-frm .round-red-btn {
    font-size: 14px !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .round-red-btn {
    color: #fff !important;
    font-size: 16px !important;
    padding: 6px 20px !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .pad-bot {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .main-ban {
    background-position-x: center;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .main-ban .container {
    max-width: 1050px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .main-header {
    position: relative;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .main-header .navbar {
    position: static;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  section.slide-up {
    margin-top: -350px !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .slide-heading {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 22px !important;
    top: -65px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .slide-heading span {
    color: #000;
    display: block;
    font-weight: 700;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .back-slid.forgt-slide {
    padding-top: 40px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .back-slid {
    height: auto;
    padding-bottom: 40px;
    padding-top: 70px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  form.forgot-frm {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  form.forgot-frm label.form-label {
    font-size: 16px !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  footer {
    position: static !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .round-with-bar:after {
    background-color: #e38c67 !important;
    bottom: 0 !important;
    content: "" !important;
    height: 170px !important;
    left: 0 !important;
    margin: auto !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    width: auto !important;
    z-index: -1 !important;
  }
}
.sliding-bg:before {
  background-color: #000;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.main-header {
  padding-top: 20px;
}

.main-header .navbar {
  padding: 0;
}

.main-header nav.flex-column a.navbar-brand {
  max-width: 300px;
}

section.slide-up {
  margin-bottom: 44px;
  margin-top: -290px;
}

/* section#test {
    margin-bottom: 44px !important;
    margin-top: -290px !important;
  } */
.slide-up .container {
  max-width: 1108px;
  position: relative;
}

.slides-here {
  position: relative;
}

form.forgot-frm {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding-left: 50px;
}

.forgot-fild {
  position: relative;
}

form.forgot-frm label.form-label {
  color: #ffffff;
  font-family: "Sarabun", sans-serif;
  font-weight: 500;
  font-size: 18px;
}

form.forgot-frm .forgot-fild .form-control::placeholder {
  color: #fff;
}

form.forgot-frm .forgot-fild input[type="submit"] {
  align-items: center;
  background-color: #fff !important;
  border: 0;
  border-radius: 76px !important;
  bottom: 4px;
  color: #e38c67;
  display: flex;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  outline: none;
  padding: 0 20px;
  position: absolute;
  right: 4px;
  text-align: center;
  top: 4px;
  transition: all 0.4s;
  width: auto;
  margin: 0;
}

form.forgot-frm .round-red-btn {
  font-size: 16px;
}

.round-red-btn,
.round-red-btn:hover {
  color: #fff;
  text-decoration: none;
  transition: all 0.4s;
}

.round-with-bar:after {
  background-color: #e38c67;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 215px;
  z-index: -1;
}

.round-bor {
  border: 9px solid #fff;
  border-radius: 50%;
  height: 327px;
  object-fit: contain;
  overflow: hidden;
  width: 327px;
}

.round-with-bar .round-bor img {
  height: 100%;
  object-fit: cover;
  /* opacity: .8; */
  width: 100%;
}

.round-with-bar {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
}

.text-contain .inc-z {
  height: 100%;
  z-index: 111;
}

form.forgot-frm .forgot-fild input.form-control.is-danger {
  border-color: red;
  color: red;
}
input.form-control.is-danger,
select.form-select.is-danger {
  border-color: red !important;
  color: red !important;
}

.form-floating.is-danger p.help.is-danger,
p.help.is-danger {
  color: red;
  font-family: Sarabun, sans-serif;
  font-size: 13px;
  font-weight: 400;
}

form.forgot-frm .forgot-fild .form-control {
  background-color: initial;
  border: 1px solid #fff;
  border-radius: 76px !important;
  color: #fff;
  font-family: Sarabun, sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 58.2px;
  padding-right: 205px;
}

form.forgot-frm .forgot-fild input[type="submit"]:hover {
  background-color: #e38c67 !important;
  color: #fff;
  transition: all 0.4s;
}

.slide-heading span {
  color: #fff;
  font-weight: 700;
}

h2.slide-heading {
  color: #e38c67;
  font-family: Oswald, sans-serif;
  font-size: 40px;
  font-weight: 300;
  left: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -92px;
  width: 100%;
  text-overflow: ellipsis;
  text-transform: uppercase;
  text-align: center;
}

.text-contain,
.text-contain .row {
  height: 100%;
}
.back-slid:after {
  background-color: #000;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}
.pad-bot {
  position: relative;
  min-height: 100vh;
}
@media (min-width: 1200px) and (max-width: 1800px) {
  .main-ban {
    background-position-x: center;
  }
}
.pad-bot {
  padding-bottom: 50px;
  position: relative;
  min-height: 100vh;
}
#button.show {
  opacity: 1;
  visibility: visible;
  z-index: 100011;
}
#button {
  align-items: center;
  background-color: #000;
  border-radius: 50%;
  bottom: 20px;
  display: flex;
  height: 40px;
  justify-content: center;
  opacity: 0;
  position: fixed;
  right: 20px;
  text-align: center;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  visibility: hidden;
  width: 40px;
  z-index: -1000;
}
#button img {
  width: 20px;
}
.whiteBg-Forgot {
  background-color: #fff !important;
  padding: 15px 15px !important;
  position: relative !important;
  z-index: 1111 !important;
  max-width: 1104px !important;
  box-shadow: 0 0 14px 0 #929292;
  margin-top: 66px;
}
section.claims-part {
  /* background-image: url(https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1675167991/SYC-SYG-Logos-Images/elite-bg_rgyes2.jpg); */
  background-repeat: no-repeat;
  float: none;
}
.form-box {
  background-color: #fff;
  box-shadow: 0 0 14px 0 #929292;
  margin-bottom: 30px;
  padding: 30px;
}

h4.prf-hed {
  color: #b51019;
  margin-bottom: 20px;
}
.form-floating {
  margin-bottom: 10px;
  margin-top: 5px;
}
div#profileBox {
  max-width: 960px !important;
  margin: 0 auto !important;
  /* border-radius: 8px; */
  /* margin-top: -240px !important; */
  margin-top: -206px !important;
  z-index: 1111 !important;
  position: relative !important;
  /* padding: 1px 42px !important; */
}
ul.timeline {
  list-style-type: none;
  padding-left: 12px;
  position: relative;
}

ul.timeline.contact-info-profile > li:last-child .form-box {
  margin-bottom: 0;
}

ul.timeline.contact-info-profile:before {
  height: 98%;
}
.single-li {
  margin-bottom: 0;
}
ul.timeline:before {
  background: #aea7a7;
  content: " ";
  display: inline-block;
  height: 99%;
  left: -14.4px;
  position: absolute;
  width: 2px;
  z-index: 400;
  top: 13px;
}
/* .single-li.timeline>li {
    padding-bottom: 30px;
} */
ul.timeline > li:before {
  background: #b51019;
  border: 3px solid #aea7a7;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 20px;
  left: -23px;
  position: absolute;
  width: 20px;
  z-index: 400;
}
input.round-red-btn.w-100.mt-3.clr {
  align-items: center;
  background: #e38c67;
  border: 1px solid #e38c67;
  border: 0;
  border-radius: 30px !important;
  color: #fff;
  display: flex;
  font-family: Sarabun, sans-serif;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  padding: 10px 28px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
ul.timeline.claim_detail > li:last-child .form-box {
  margin-bottom: 0;
}
input.round-red-btn.w-100.mt-3.clr:hover {
  background: #00a9e0 !important;
  border: 1px solid #00a9e0 !important;
  color: #fff;
}

div#FileClaimsBox {
  max-width: 960px !important;
  margin: 0 auto !important;
  /* border-radius: 8px; */
  /* margin-top: -240px !important; */
  margin-top: -206px !important;
  z-index: 1111 !important;
  position: relative !important;
  /* padding: 1px 42px !important; */
}
input.round-red-btn.w-100.mt-3 {
  align-items: center;
  background: #b51019;
  border: 1px solid #b51019;
  border: 0;
  border-radius: 30px !important;
  color: #fff;
  display: flex;
  font-family: Sarabun, sans-serif;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  padding: 10px 28px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
input.round-red-btn.w-100.mt-3:hover {
  background: #000 !important;
  border: 1px solid #000 !important;
  color: #fff;
}
input#file {
  margin-top: 8px;
}
input[type="file"]::file-selector-button {
  border: 2px solid #abacac;
  padding: 0.2em 0.4em;
  /* border-radius: .2em; */
  background-color: #abacac;
  transition: 1s;
  margin-top: 1px;
}
label.radio-custom1-label:before {
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 23px;
  margin-right: 10px;
  margin-top: -2px;
  text-align: center;
  vertical-align: middle;
  width: 23px;
}
label.radio-custom1-label.is-danger {
  color: red;
}
.form-input {
  display: block;
  width: 100%;
  /* padding: 0.375rem 2.25rem 1.375rem 0.75rem; */
  padding: 14.375px 2.25rem 2.375rem 13.75px;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  padding-bottom: 17px;
  padding-left: 244px;
}
.form-floating.is-danger input.form-control,
.form-floating.is-danger .form-input {
  border: 1px solid red;
  color: red;
}
.claimPrice {
  background-color: #e38c67;
  color: #fff;
  text-align: center;
  padding-left: -17px;
  width: fit-content;
  /* margin-left: 156px; */
  position: relative;
  font-weight: 700;
  border-radius: 0.25rem;
  display: block;
  padding: 2px 10px;
  margin-left: auto;
}
.claimPrice2 {
  background-color: #e38c67;
  color: #fff;
  text-align: center;
  padding-left: -17px;
  width: fit-content;
  /* margin-left: 156px; */
  position: relative;
  font-weight: 700;
  border-radius: 0.25rem;
  display: block;
  padding: 2px 10px;
  margin-left: auto;
}
.claimPrice3 {
  background-color: #e38c67;
  color: #fff;
  text-align: center;
  padding-left: -17px;
  width: fit-content;
  /* margin-left: 156px; */
  position: relative;
  font-weight: 700;
  border-radius: 0.25rem;
  display: block;
  padding: 2px 10px;
  margin-left: auto;
}

span.spinner-border.spinner-border-sm2 {
  position: absolute !important;
  right: 12% !important;
  z-index: 1 !important;
  top: 49% !important;
}

span.spinner-border.spinner-border-sm3 {
  position: absolute !important;
  right: 12% !important;
  z-index: 1 !important;
  top: 81% !important;
}
span.spinner-border.spinner-border-smSplit1 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
span.spinner-border.spinner-border-smSplit2 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  top: 81% !important;
}

.spinner-border-sm2 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-border-sm3 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
