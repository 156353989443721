
  h4.header.white.lighter.bigger {
    color: #fff !important;
    /* font-weight: 400; */
    text-transform: uppercase;
    text-align: center;
}
.space-6 {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 6px 0 5px;
}
hr.underLine {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 12px 0;
    margin: 6px 0 5px;
    color: #fff;
}

